import React from 'react';
import PriceCard from '../components/PriceCard';

const Home = () => {
  return (
    <>
      <PriceCard />
    </>
  );
};

export default Home;